// src/property/datasource.js
export const scheduleData = [
    // Your schedule data goes here
    {
        Id: 1,
        Subject: 'Meeting',
        StartTime: new Date(2019, 0, 10, 10, 0),
        EndTime: new Date(2019, 0, 10, 11, 0),
    },
    // Add more events as needed
];
