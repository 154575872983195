<template>
    <div>
        <div id='app'>
            <div id='container'>
                <div class="form-group mb-2 d-flex justify-content-end">
                    <button type="button" class="import-btn ml-2" @click="back()">Back</button>
                </div>
                <div class="row">
                    <div class="col lg-6">
                        <div class="form-group mb-2 d-flex align-items-center">
                            <!-- Dropdown -->
                            <select class="form-control mr-2" v-model="selectedOption">
                                <option value="Airbnb">Airbnb</option>
                                <option value="Booking.com">Booking.com</option>
                                <option value="Vrbo.com">Vrbo.com</option>
                                <option value="other">Other</option> <!-- Option for manual input -->
                            </select>
                            <input v-if="selectedOption === 'other'" type="text" placeholder="Enter custom option"
                                class="data-input mr-2" v-model="manualOption" style="width: 50%;" />

                            <input type="text" placeholder="Enter ICal URL here" class="data-input mr-2"
                                v-model="icalUrl" style="width: 50%;" />
                            <button class="import-btn" @click="importIcal">Import</button>
                        </div>
                    </div>
                    <div class="col lg-6">
                        <div class="form-group mb-2">
                            <input type="text" placeholder="ICal Link for this property" class="data-input ml-2"
                                v-model="exporturl" style="width: 80%;" />
                            <button class="import-btn ml-2" @click="exportical">Export</button>

                        </div>
                    </div>
                </div>
                <ejs-schedule ref="scheduleObj" height="520px" :selectedDate='selectedDate'
                    :eventSettings='eventSettings' :currentView='currentView'>
                    <e-views>
                        <e-view option="Day"></e-view>
                        <e-view option="Week"></e-view>
                        <e-view option="WorkWeek"></e-view>
                        <e-view option="Month"></e-view>
                        <e-view option="Agenda"></e-view>
                    </e-views>
                </ejs-schedule>
            </div>
        </div>
    </div>
</template>
<script>
import { ScheduleComponent, ViewDirective, ViewsDirective, Day, Week, WorkWeek, Month, Agenda, Resize, DragAndDrop, ICalendarExport, ICalendarImport } from "@syncfusion/ej2-vue-schedule";
import { UploaderComponent } from '@syncfusion/ej2-vue-inputs';
import { ButtonComponent } from "@syncfusion/ej2-vue-buttons";
import { scheduleData } from './datasource.js';
import API from '../api'

export default {
    name: "App",
    components: {
        // "ejs-uploader": UploaderComponent,
        "ejs-schedule": ScheduleComponent,
        "e-views": ViewsDirective,
        "e-view": ViewDirective
    },
    data: function () {
        return {
            eventSettings: { dataSource: scheduleData },
            selectedDate: new Date(),
            currentView: 'Month',
            cssClass: 'calendar-import',
            multiple: false,
            extensions: '.ics',
            buttons: {
                browse: 'Choose file',
            },
            showFileList: false,
            icalUrl: '',
            exporturl: '',
            manualOption: '',
            selectedOption: '',
            sitename: '',
        }
    },
    provide: {
        schedule: [Day, Week, WorkWeek, Month, Agenda, Resize, DragAndDrop, ICalendarExport, ICalendarImport]
    },
    mounted() {
        this.Autoimport();
        this.exportical();
    },
    methods: {
        back() {
            this.$router.push({ name: 'properties' });
        },
        onSelect: function (args) {
            let scheduleObj = this.$refs.scheduleObj.ej2Instances;
            scheduleObj.importICalendar(args.event.target.files[0]);
        },
        exportical() {
            API.exportcalander(this.$route.params.id, data => {
                this.exporturl = data.data.url;
            }, error => {
                console.log(error);
            });

        },
        // onClick: function () {
        //     let scheduleObj = this.$refs.scheduleObj.ej2Instances;
        //     scheduleObj.exportToICalendar();
        // },
        async Autoimport(param1) {
            const scheduleObj = this.$refs.scheduleObj?.ej2Instances;
            const params = {
                Property_id: this.$route.params.id,
            };

            if (scheduleObj) {
                API.geticaldata(params, data => {
                    // console.log(data);
                    scheduleObj.importICalendar(data);
                }, error => {
                    console.log(error);
                });


                if (!param1) {
                    API.bookingdetails(params, data => {
                        // console.log(data);
                        scheduleObj.importICalendar(data);
                    }, error => {
                        console.log(error);
                    });
                }
            }
        },
        importIcal() {
            if (this.selectedOption === 'other' && this.manualOption) {
                this.sitename = this.manualOption
                console.log('Importing custom option:', this.icalUrl);
            } else {
                this.sitename = this.selectedOption
                console.log('Importing ICal URL:', this.icalUrl);
            }
            if (!this.icalUrl) {
                alert('Please enter a valid URL.');
                return;
            }
            const params = { url: this.icalUrl, propertyid: this.$route.params.id, site: this.sitename };
            API.saveicalfile(params, data => {
                this.eventSettings.dataSource = [];
                this.Autoimport('yes');
            }, error => {
                console.error(error);
            });
        },
    }
}
</script>
<style>
.calendar-import.e-upload {
    border: 0;
    padding-left: 0 !important;
}

.calendar-import.e-upload .e-file-select-wrap {
    padding: 0
}

.calendar-import.e-upload .e-file-select-wrap .e-file-drop {
    display: none;
}

.e-file-select-wrap {
    margin-left: 20%;
}

.form-group {
    display: flex;
    /* Aligns input and button in a row */
    align-items: center;
    /* Vertically centers the items */
    margin-bottom: 10px;
    /* Spacing below the container */
}

.data-input {
    padding: 10px;
    border: 1px solid #ccc !important;
    border-radius: 5px;
    margin-right: 10px;
    font-size: 16px;
}

.data-input:focus {
    border-color: #007bff;
    outline: none;
}

.import-btn {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
}

.import-btn:hover {
    background-color: #0056b3;
}
</style>